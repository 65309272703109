import React from "react"

import axios from "axios"

import {
  Grid,
  Box,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core"

import Section from "../sections/section"
import Button from "../modules/button"

const isValidEmail = email =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false
const isValidLength = string => (string.length > 2 ? true : false)

const CustomField = props => {
  const { label, placeholder, id, onChange, multiline, value, error } = props
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      id={id}
      value={value}
      error={error ? true : false}
      helperText={error}
      variant="outlined"
      fullWidth
      onChange={onChange}
      multiline={multiline ? true : false}
      minRows={multiline ? 6 : 1}
      color="secondary"
    />
  )
}

const CustomForm = props => {
  const { location } = props

  const defaultFields = [
    {
      id: "name",
      label: "Nom",
      placeholder: "Votre nom...",
      multiline: false,
      value: "",
      error: "",
    },
    {
      id: "email",
      label: "Email",
      placeholder: "Votre adresse email...",
      multiline: false,
      value: "",
      error: "",
    },
    {
      id: "subject",
      label: "Objet",
      placeholder: "Objet de votre message...",
      multiline: false,
      value: "",
      error: "",
    },
    {
      id: "message",
      label: "Message",
      placeholder: "Votre message...",
      multiline: true,
      value: "",
      error: "",
    },
  ]

  const [fields, setFields] = React.useState(defaultFields)
  // console.log("fields", fields)

  const [loading, setLoading] = React.useState(false)
  const [notification, setNotification] = React.useState(false)

  const errorsCount = fields.filter(
    field =>
      field.error.length > 0 ||
      (field.error.length === 0 && field.value.length === 0)
  ).length

  const handleUpdateField = e => {
    const { id, value } = e.target

    // console.log(id, value)

    let nextFields = [...fields]
    const fieldIndex = fields.findIndex(field => field.id === id)

    const updateField = props => {
      nextFields[fieldIndex] = {
        ...nextFields[fieldIndex],
        value: props.value,
        error: props.error,
      }
      setFields(nextFields)
    }

    if (!isValidLength(value)) {
      // length is too short ==> error
      updateField({
        value: value,
        error: "Ce champ doit contenir au moins 3 caractères...",
      })
    } else {
      // length is valid ==> continue

      if (id !== "email") {
        // type is not email ==> ok
        updateField({
          value: value,
          error: "",
        })
      } else {
        // type is email ==> continue

        if (isValidEmail(value)) {
          // email is valid ==> ok
          updateField({
            value: value,
            error: "",
          })
        } else {
          // email is not valid ==> error
          updateField({
            value: value,
            error: "Ce champ doit contenir une adresse email valide...",
          })
        }
      }
    }
  }

  const handleSendForm = () => {
    console.log("handleSendForm", fields)
    if (errorsCount === 0) {
      setLoading(true)

      const findField = id => fields.find(field => field.id === id).value

      const hostName = location.hostname
      const toEmail = "lyon.gus@gmail.com"

      const fromName = findField("name")
      const fromEmail = findField("email")
      const subject = "[ " + hostName + " ] " + findField("subject")
      const message =
        findField("message").replace(/\r?\n/g, "<br/>") +
        "<br/><br/>Message automatique envoyé depuis " +
        hostName

      axios
        .post(`https://api.emailjs.com/api/v1.0/email/send`, {
          service_id: process.env.GATSBY_EMAILJS_SERVICE,
          template_id: process.env.GATSBY_EMAILJS_TEMPLATE,
          user_id: process.env.GATSBY_EMAILJS_USER,
          template_params: {
            to_email: toEmail,
            from_name: fromName,
            from_email: fromEmail,
            subject: subject,
            message: message,
          },
        })
        .then(response => {
          const { data } = response
          console.log("response data", data)
          setLoading(false)
          setFields(defaultFields)
          setNotification(true)
        })
        .catch(error => {
          console.log("error", error)
          alert("Erreur : " + error)
          setLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      {fields.map(field => {
        const { id, label, placeholder, multiline, value, error } = field
        return (
          <React.Fragment key={id}>
            <Grid item xs={12} sm={10} md={8}>
              <CustomField
                id={id}
                label={label}
                placeholder={placeholder}
                multiline={multiline}
                value={value}
                error={error}
                onChange={handleUpdateField}
              />
            </Grid>
          </React.Fragment>
        )
      })}

      <Grid item xs={12} sm={10} md={8}>
        <Box display="flex" alignItems="center">
          <Button
            background="LIGHT"
            linkLabel="Envoyer"
            type="SEND"
            disabled={errorsCount > 0 || loading}
            onClick={handleSendForm}
          />
          {loading && (
            <Box margin={2} component="span">
              <CircularProgress size={24} color="inherit" />
            </Box>
          )}
          {loading && <Box component="span">Envoi en cours...</Box>}
          {notification && (
            <Box margin={2} color="success.main" component="b">
              Votre message a bien été envoyé !
            </Box>
          )}
        </Box>
      </Grid>
    </React.Fragment>
  )
}

const Contact = props => (
  <Section title="Nous contacter" titleColor="SECONDARY" background="LIGHT">
    <Grid item xs={12} sm={10} md={8}>
      <Typography component={"div"}>
        <Box color="text.secondary">
          Pour nous envoyer un mail, renseignez le formulaire suivant :
        </Box>
      </Typography>
    </Grid>

    <CustomForm {...props} />
  </Section>
)

export default Contact
